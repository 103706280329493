export const userRoutes = [
  {
    name: "dashboard",
    path: "index/:id?",
    component: () =>
      import(
        /* webpackChunkName:"user-dashboard" */ "@/views/user/dashboard/dashboard.vue"
      ),
    meta: {
      title: "Dashboard",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "developer",
        "customer support",
        "accountant",
      ],
    },
  },
  {
    name: "payroll",
    path: "payroll",
    component: () =>
      import(
        /* webpackChunkName:"payroll" */ "@/views/user/payroll/payroll.vue"
      ),
    meta: {
      title: "Payroll",
      auth: "user",
      roles: ["admin", "operations", "accountant", "human resources"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "payrollDetails",
    path: "payroll-details/:txref",
    component: () =>
      import(
        /* webpackChunkName:"payroll" */ "@/views/user/payroll/payroll-details.vue"
      ),
    meta: {
      title: "Payroll Details",
      auth: "user",
      roles: ["admin", "operations", "accountant", "human resources"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Payroll", route: "payroll" },
      ],
    },
  },
  {
    name: "bankAccounts",
    path: "bank-accounts",
    component: () =>
      import(
        /* webpackChunkName:"bank-accounts" */ "@/views/user/bankAccounts.vue"
      ),
    meta: {
      title: "My Bank Accounts",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [{ title: "Receive Money", route: "receive-money" }],
    },
  },
  {
    name: "allServices",
    path: "all-services",
    component: () =>
      import(
        /* webpackChunkName:"all-services" */ "@/views/user/services/services.vue"
      ),
    meta: {
      title: "All Services",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "developer",
        "human resources",
        "accountant",
      ],
    },
  },
  {
    name: "employee",
    path: "employee-list",
    component: () =>
      import(
        /* webpackChunkName:"user-employee-list" */ "@/views/user/employee/employee.vue"
      ),
    meta: {
      title: "Employee List",
      auth: "user",
      roles: ["admin", "operations", "human resources"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "businessBranch",
    path: "business-branches",
    component: () =>
      import(
        /* webpackChunkName:"business-branches" */ "@/views/user/branch/branch.vue"
      ),
    meta: {
      title: "Business Branches",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "businessDashboard",
    path: "business-dashboard",
    component: () =>
      import(
        /* webpackChunkName:"business-dashboard" */ "@/views/user/branch/businessDashboard"
      ),
    meta: {
      title: "My Business Dashboard",
      auth: "user",
      roles: ["admin", "operations", "manager"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "branchDetails",
    path: "branch-dashboard/:id/:name",
    component: () =>
      import(
        /* webpackChunkName:"branch-details" */ "@/views/user/branch/branchDetails"
      ),
    meta: {
      title: "Branch Details",
      auth: "user",
      roles: ["admin", "operations", "manager"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Branches", route: "businessBranch" },
      ],
    },
  },
  {
    name: "paypointDetails",
    path: "paypoint-details/:branchid/:id",
    component: () =>
      import(
        /* webpackChunkName:"paypoint-details" */ "@/views/user/branch/sales-point-details.vue"
      ),
    meta: {
      title: "Pay Point Details",
      auth: "user",
      roles: ["admin", "operations", "manager"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Branches", route: "businessBranch" },
      ],
    },
  },
  {
    name: "cashierDetails",
    path: "cashier-details/:branchid/:teamid",
    component: () =>
      import(
        /* webpackChunkName:"cashier-details" */ "@/views/user/branch/cashierDetails"
      ),
    meta: {
      title: "Cashier Details",
      auth: "user",
      roles: ["admin", "operations", "manager"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Branches", route: "businessBranch" },
      ],
    },
  },
  {
    name: "cashierTransactions",
    path: "cashier-transactions",
    component: () =>
      import(
        /* webpackChunkName:"cashier-transactions" */ "@/views/user/branch/cashierTransactions.vue"
      ),
    meta: {
      title: "Transactions",
      auth: "user",
      roles: ["cashier", "admin"],
    },
  },
  {
    name: "subaccount-dashboard",
    path: "subaccount-dashboard",
    component: () =>
      import(
        /* webpackChunkName:"subaccount-dashboard" */ "@/views/user/subaccount/dashboard/index.vue"
      ),
    meta: {
      title: "Dashboard",
      auth: "user",
      roles: ["customer"],
    },
  },
  {
    name: "subaccount-transaction",
    path: "subaccount-transaction",
    component: () =>
      import(
        /* webpackChunkName:"subaccount-transaction" */ "@/views/user/subaccount/dashboard/sub-transaction.vue"
      ),
    meta: {
      title: "Transactions",
      auth: "user",
      roles: ["customer"],
    },
  },
  {
    name: "subaccount",
    path: "mycustomer",
    component: () =>
      import(
        /* webpackChunkName:"customer" */ "@/views/user/subaccount/sub-account.vue"
      ),
    meta: {
      title: "Customer Plus",
      auth: "user",
      roles: ["admin", "operations", "human resources", "accountant"],
      breadcrumbs: [{ title: "Modules", route: "modules" }],
    },
  },
  {
    name: "subaccount1",
    path: "mycustomer11",
    component: () =>
      import(
        /* webpackChunkName:"customer" */ "@/views/user/subaccount/customer-plus.vue"
      ),
    meta: {
      title: "Customer Plus",
      auth: "user",
      roles: ["admin", "operations", "human resources", "accountant"],
      breadcrumbs: [],
    },
  },
  {
    name: "add-customer",
    path: "add-customer-plus",
    component: () =>
      import(
        /* webpackChunkName:"customer-plus" */ "@/views/user/subaccount/add-customer.vue"
      ),
    meta: {
      title: "Add Customer",
      auth: "user",
      roles: ["admin", "operations", "human resources"],
      breadcrumbs: [{ title: "Customer Plus", route: "subaccount" }],
    },
  },
  {
    name: "subaccount-details",
    path: "customer-history/:trackingid",
    component: () =>
      import(
        /* webpackChunkName:"subaccount-details" */ "@/views/user/subaccount/subaccount-details.vue"
      ),
    meta: {
      title: "Customer Details",
      auth: "user",
      roles: ["admin", "operations", "human resources", "accountant"],
      breadcrumbs: [{ title: "Customers", route: "subaccount" }],
    },
  },
  {
    name: "receive-money",
    path: "receive-money",
    component: () =>
      import(
        /* webpackChunkName:"receive-money" */ "@/views/user/receive/receive-money.vue"
      ),
    meta: {
      title: "Receive Money",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [],
    },
  },
  {
    name: "settings",
    path: "settings/:tab?",
    component: () =>
      import(
        /* webpackChunkName:"user-settings" */ "@/views/user/settings/settings.vue"
      ),
    meta: {
      title: "Settings",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "manager",
        "cashier",
        "developer",
        "customer support",
        "human resources",
        "accountant",
      ],
      breadcrumbs: [],
    },
  },
  {
    name: "gen-settings",
    path: "general-settings",
    component: () =>
      import(
        /* webpackChunkName:"general-settings" */ "@/views/user/settings/general.vue"
      ),
    meta: {
      title: "General",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "manager",
        "cashier",
        "developer",
        "customer support",
        "human resources",
        "accountant",
      ],
      breadcrumbs: [{ title: "Settings", route: "settings" }],
    },
  },
  {
    name: "verifications",
    path: "verifications",
    component: () =>
      import(
        /* webpackChunkName:"verifications" */ "@/views/user/settings/account-verifications.vue"
      ),
    meta: {
      title: "Verification",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "Settings", route: "settings" }],
    },
  },
  {
    name: "payout-account",
    path: "payout-accounts",
    component: () =>
      import(
        /* webpackChunkName:"payout-accounts" */ "@/views/user/settings/payout.vue"
      ),
    meta: {
      title: "Payout Accounts",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "Settings", route: "settings" }],
    },
  },
  {
    name: "add-payout",
    path: "add-payout",
    component: () =>
      import(
        /* webpackChunkName:"add-payout" */ "@/views/user/settings/add-payout.vue"
      ),
    meta: {
      title: "Add Account",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "Settings", route: "settings" },
        { title: "Payout Accounts", route: "payout-account" },
      ],
    },
  },
  {
    name: "teams",
    path: "teams",
    component: () =>
      import(/* webpackChunkName:"teams" */ "@/views/user/settings/team.vue"),
    meta: {
      title: "Teams",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "Settings", route: "settings" }],
    },
  },
  {
    name: "invite-staff",
    path: "invite-team",
    component: () =>
      import(
        /* webpackChunkName:"invite-staff" */ "@/views/user/settings/invite-staff"
      ),
    meta: {
      title: "Invite Team Member",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "Settings", route: "settings" },
        { title: "Teams", route: "teams" },
      ],
    },
  },

  {
    name: "preferences",
    path: "preferences",
    component: () =>
      import(
        /* webpackChunkName:"preferences" */ "@/views/user/settings/preference"
      ),
    meta: {
      title: "Preferences",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "Settings", route: "settings" }],
    },
  },
  {
    name: "tfa-settings",
    path: "tfa-settings",
    component: () =>
      import(
        /* webpackChunkName:"tfa-settings" */ "@/views/user/settings/two-factor"
      ),
    meta: {
      title: "2FA Setup",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "Settings", route: "settings" }],
    },
  },
  {
    name: "myloan",
    path: "loan",
    component: () =>
      import(/* webpackChunkName:"myloan" */ "@/views/user/loan/loan"),
    meta: {
      title: "Loan",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "payout-advance",
    path: "payout-advance",
    component: () =>
      import(
        /* webpackChunkName:"payout-advance" */ "@/views/user/loan/payout-advance"
      ),
    meta: {
      title: "Payout Advance",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "convert-currency",
    path: "currency-conversion",
    component: () =>
      import(
        /* webpackChunkName:"convert-currency" */ "@/views/user/convert-currency/convert-currency"
      ),
    meta: {
      title: "Currency Swap",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "events",
    path: "events",
    component: () =>
      import(/* webpackChunkName:"events" */ "@/views/user/events/events"),
    meta: {
      title: "Events",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "Modules", route: "modules" }],
    },
  },
  {
    name: "add-event",
    path: "add-event",
    component: () =>
      import(
        /* webpackChunkName:"add-event" */ "@/views/user/events/create-event"
      ),
    meta: {
      title: "Create Event",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Events", route: "events" },
      ],
    },
  },
  {
    name: "add-ticket",
    path: "add-ticket/:id",
    component: () =>
      import(
        /* webpackChunkName:"add-ticket" */ "@/views/user/events/create-event"
      ),
    meta: {
      title: "Create Ticket",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Events", route: "events" },
      ],
    },
  },
  {
    name: "event-details",
    path: "event-details/:id",
    component: () =>
      import(
        /* webpackChunkName:"event-details" */ "@/views/user/events/event-details"
      ),
    meta: {
      title: "Event Details",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Events", route: "events" },
      ],
    },
  },

  {
    name: "disputes",
    path: "disputes",
    component: () =>
      import(
        /* webpackChunkName:"user-disputes" */ "@/views/user/dispute/disputes.vue"
      ),
    meta: {
      title: "Disputes",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [],
    },
  },
  {
    name: "cashier-mfi",
    path: "cashier-mfi",
    component: () =>
      import(
        /* webpackChunkName:"cahier-mfi" */ "@/views/user/mfi/cashier-mfi.vue"
      ),
    meta: {
      title: "Cashier MFI",
      auth: "user",
      roles: ["cashiermfi"],
      breadcrumbs: [],
    },
  },
  {
    name: "manager-mfi",
    path: "manager-mfi",
    component: () =>
      import(
        /* webpackChunkName:"manager-mfi" */ "@/views/user/mfi/manager-mfi.vue"
      ),
    meta: {
      title: "Manager MFI",
      auth: "user",
      roles: ["admin", "operations", "accountant", "operations_manager"],
      breadcrumbs: [],
    },
  },
  {
    name: "mfi-stats",
    path: "mfi-stats",
    component: () =>
      import(
        /* webpackChunkName:"mfi-stats" */ "@/views/user/mfi/mfi-stat.vue"
      ),
    meta: {
      title: "MFI Stats",
      auth: "user",
      roles: ["admin", "operations", "accountant", "operations_manager"],
      breadcrumbs: [],
    },
  },
  {
    name: "boldd-mfi-loan",
    path: "boldd-mfi-loan/:id",
    component: () =>
      import(
        /* webpackChunkName:"boldd-mfi-loan" */ "@/views/user/mfi/loan-application.vue"
      ),
    meta: {
      title: "Loan Application",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "accountant",
        "cashiermfi",
        "operations_manager",
      ],
      breadcrumbs: [],
    },
  },
  {
    name: "mfi-loan-history",
    path: "mfi-loan-history/:id",
    component: () =>
      import(
        /* webpackChunkName:"mfi-loan-history" */ "@/views/user/mfi/mfi-loan-history.vue"
      ),
    meta: {
      title: "Loan Applications",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "accountant",
        "cashiermfi",
        "operations_manager",
      ],
      breadcrumbs: [],
    },
  },
  {
    name: "mfi-loan-details",
    path: "mfi-loan-details/:id",
    component: () =>
      import(
        /* webpackChunkName:"mfi-loan-details" */ "@/views/user/mfi/loan-details.vue"
      ),
    meta: {
      title: "Loan Details",
      auth: "user",
      roles: ["admin", "operations", "accountant", "operations_manager"],
      breadcrumbs: [],
    },
  },
  {
    name: "approved_loan_history",
    path: "approved_loan_history/:id",
    component: () =>
      import(
        /* webpackChunkName:"approved_loan_history" */ "@/views/user/mfi/mfi-loan/approved-loan-history.vue"
      ),
    meta: {
      title: "Loan Repayment History",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "accountant",
        "cashiermfi",
        "operations_manager",
      ],
      breadcrumbs: [],
    },
  },
  {
    name: "mfi_success",
    path: "mfi_success",
    component: () =>
      import(
        /* webpackChunkName:"trans_success" */ "@/views/user/mfi/mfi-success.vue"
      ),
    meta: {
      title: "MFI Success",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "accountant",
        "cashiermfi",
        "operations_manager",
      ],
      breadcrumbs: [],
    },
  },
  {
    name: "mfi_details",
    path: "mfi_details",
    component: () =>
      import(
        /* webpackChunkName:"mfi_details" */ "@/views/user/mfi/details-page.vue"
      ),
    meta: {
      title: "Details",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "accountant",
        "cashiermfi",
        "operations_manager",
      ],
      breadcrumbs: [],
    },
  },
  {
    name: "update-requests",
    path: "mfi_account_update_request",
    component: () =>
      import(
        /* webpackChunkName:"mfi_account_update_request" */ "@/views/user/mfi/update-requests.vue"
      ),
    meta: {
      title: "Update Requests",
      auth: "user",
      roles: ["admin", "operations", "accountant", "operations_manager"],
      breadcrumbs: [],
    },
  },
  {
    name: "loan-manager",
    path: "mfi-loan-manager",
    component: () =>
      import(
        /* webpackChunkName:"mfi-loan-manager" */ "@/views/user/modules/mfi-loan-manager.vue"
      ),
    meta: {
      title: "Loan Manager",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [{ title: "Modules", route: "modules" }],
    },
  },
  {
    name: "loan-manager-details",
    path: "mfi-loan-manager-details",
    component: () =>
      import(
        /* webpackChunkName:"mfi-loan-manager-details" */ "@/views/user/modules/mfi-loan-manager-details.vue"
      ),
    meta: {
      title: "Loan Manager Details",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "accountant",
        "operations_manager",
        "cashiermfi",
      ],
      breadcrumbs: [
        { title: "Modules", route: "modules" },
        { title: "Loan Manager", route: "loan-manager" },
      ],
    },
  },
  {
    name: "modules",
    path: "modules",
    component: () =>
      import(
        /* webpackChunkName:"modules" */ "@/views/user/modules/modules.vue"
      ),
    meta: {
      title: "Modules",
      auth: "user",
      roles: ["admin", "operations", "accountant", "human resources"],
      breadcrumbs: [],
    },
  },
  {
    name: "developer",
    path: "developer",
    component: () =>
      import(
        /* webpackChunkName:"developer" */ "@/views/user/developer/developer.vue"
      ),
    meta: {
      title: "Developer",
      auth: "user",
      roles: ["admin", "operations", "developer"],
      breadcrumbs: [],
    },
  },
  {
    name: "developer-settings",
    path: "developer-settings",
    component: () =>
      import(
        /* webpackChunkName:"developer-settings" */ "@/views/user/developer/settings.vue"
      ),
    meta: {
      title: "Developer Settings",
      auth: "user",
      roles: ["admin", "operations", "developer"],
      breadcrumbs: [{ title: "Developer", route: "developer" }],
    },
  },
  {
    name: "virtual-accounts",
    path: "virtual-accounts",
    component: () =>
      import(
        /* webpackChunkName:"virtual-accounts" */ "@/views/user/developer/virtual-accounts.vue"
      ),
    meta: {
      title: "Virtual Accounts",
      auth: "user",
      roles: ["admin", "operations", "developer"],
      breadcrumbs: [{ title: "Developer", route: "developer" }],
    },
  },
  {
    name: "virtual-trans",
    path: "virtual-transactions",
    component: () =>
      import(
        /* webpackChunkName:"virtual-trans" */ "@/views/user/developer/virtual-accounts-trans.vue"
      ),
    meta: {
      title: "Transactions",
      auth: "user",
      roles: ["admin", "operations", "developer"],
      breadcrumbs: [
        { title: "Developer", route: "developer" },
        { title: "Virtual Accounts", route: "virtual-accounts" },
      ],
    },
  },
  {
    name: "api-requests",
    path: "api-requests",
    component: () =>
      import(
        /* webpackChunkName:"api-requests" */ "@/views/user/developer/api-requests.vue"
      ),
    meta: {
      title: "API Requests",
      auth: "user",
      roles: ["admin", "operations", "developer"],
      breadcrumbs: [{ title: "Developer", route: "developer" }],
    },
  },
  {
    name: "webhooks",
    path: "webhooks",
    component: () =>
      import(
        /* webpackChunkName:"webhooks" */ "@/views/user/developer/webhooks.vue"
      ),
    meta: {
      title: "Webhooks",
      auth: "user",
      roles: ["admin", "operations", "developer"],
      breadcrumbs: [{ title: "Developer", route: "developer" }],
    },
  },
  {
    name: "collections",
    path: "collections",
    component: () =>
      import(
        /* webpackChunkName:"collections" */ "@/views/user/collections/home.vue"
      ),
    meta: {
      title: "Collection Dashboard",
      auth: "user",
      roles: ["admin", "operations", "accountant", "developer"],
      breadcrumbs: [],
    },
  },
  {
    name: "api-collections",
    path: "ledger-trans",
    component: () =>
      import(
        /* webpackChunkName:"api-collections" */ "@/views/user/collections/collections.vue"
      ),
    meta: {
      title: "Collections",
      auth: "user",
      roles: ["admin", "operations", "accountant", "developer"],
      breadcrumbs: [{ title: "Collections", route: "collections" }],
    },
  },
  {
    name: "subaccount-trans",
    path: "subaccount-trans",
    component: () =>
      import(
        /* webpackChunkName:"subaccount-trans" */ "@/views/user/collections/subaccount-trans.vue"
      ),
    meta: {
      title: "Subaccount Transactions",
      auth: "user",
      roles: ["admin", "operations", "accountant", "developer"],
      breadcrumbs: [{ title: "Collections", route: "collections" }],
    },
  },
  {
    name: "api-collections-details",
    path: "ledger-trans/:reference",
    component: () =>
      import(
        /* webpackChunkName:"api-collections-details" */ "@/views/user/collections/collection-details.vue"
      ),
    meta: {
      title: "Details",
      auth: "user",
      roles: ["admin", "operations", "accountant", "developer"],
      breadcrumbs: [{ title: "Collections", route: "collections" }],
    },
  },
  {
    name: "cac",
    path: "/addon/cac",
    component: () =>
      import(/* webpackChunkName:"user-cac" */ "@/views/user/addons/cac"),
    meta: {
      title: "Business CAC registration",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Add-On", route: "addon" },
      ],
    },
    params: true,
  },
  {
    name: "success-page",
    path: "success-page",
    component: () =>
      import(
        /* webpackChunkName:"success-page" */ "@/views/user/success-page.vue"
      ),
    meta: {
      title: "Success",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "manager",
        "cashier",
        "developer",
        "customer support",
        "human resources",
        "accountant",
        "customer",
      ],
      // breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
    params: true,
  },
  {
    name: "manual-funding",
    path: "manual-funding",
    component: () =>
      import(
        /* webpackChunkName:"manual-funding" */ "@/views/user/receive/manual-funding"
      ),
    meta: {
      title: "Manual Funding",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "Receive Money", route: "receive-money" }],
    },
  },
  {
    name: "usd-account",
    path: "usd-account",
    component: () =>
      import(
        /* webpackChunkName:"usd-account" */ "@/views/user/receive/usd-account"
      ),
    meta: {
      title: "USD Account",
      auth: "user",
      roles: ["admin", "operations", "manager", "cashier", "accountant"],
      breadcrumbs: [{ title: "Receive Money", route: "receive-money" }],
    },
  },
  {
    name: "usd-account-request",
    path: "usd-account-request",
    component: () =>
      import(
        /* webpackChunkName:"usd-account-request" */ "@/views/user/receive/usd-account-request"
      ),
    meta: {
      title: "USD Account Request",
      auth: "user",
      roles: ["admin", "operations", "manager", "cashier", "accountant"],
      breadcrumbs: [{ title: "Receive Money", route: "receive-money" }],
    },
  },
  {
    name: "transfer-details",
    path: "transfer-details",
    component: () =>
      import(
        /* webpackChunkName:"transfer-details" */ "@/views/user/transfer/transfer-details"
      ),
    meta: {
      title: "Transfer Details",
      auth: "user",
      roles: [
        "admin",
        "operations",
        "manager",
        "cashier",
        "developer",
        "customer support",
        "human resources",
        "accountant",
      ],
      breadcrumbs: [],
    },
  },
  {
    name: "transfer",
    path: "transfer",
    component: () =>
      import(
        /* webpackChunkName:"user-transfer" */ "@/views/user/transfer/transfer-home.vue"
      ),
    meta: {
      title: "Transfer",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [],
    },
  },
  {
    name: "p2p-transfer",
    path: "p2p-transfer",
    component: () =>
      import(
        /* webpackChunkName:"p2p-transfer" */ "@/views/user/transfer/p2p-transfer.vue"
      ),
    meta: {
      title: "1app to 1app Transfer",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [],
    },
  },
  {
    name: "mobile-money",
    path: "mobile-money",
    component: () =>
      import(
        /* webpackChunkName:"mobile-money" */ "@/views/user/transfer/mobile-money.vue"
      ),
    meta: {
      title: "Mobile Money",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [],
    },
  },
  {
    name: "upgrade",
    path: "pricing",
    component: () =>
      import(
        /* webpackChunkName:"upgrade-pricing" */ "@/views/user/upgrade/benefits.vue"
      ),
    meta: {
      title: "Pricing",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [],
    },
  },

  // virtual  cards
  {
    name: "card",
    path: "cards",
    component: () =>
      import(
        /* webpackChunkName:"virtual-cards" */ "@/views/user/card/cards.vue"
      ),
    meta: {
      title: "Virtual Cards",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "create-card",
    path: "create-card",
    component: () =>
      import(
        /* webpackChunkName:"create-card" */ "@/views/user/card/create_card.vue"
      ),
    meta: {
      title: "Virtual Card",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Cards", route: "card" },
      ],
    },
  },
  {
    name: "card-details",
    path: "card-details",
    component: () =>
      import(
        /* webpackChunkName:"card-details" */ "@/views/user/card/card_details.vue"
      ),
    meta: {
      title: "Card Details",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Cards", route: "card" },
      ],
    },
  },
];
